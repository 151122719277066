// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
// $base-url: '' !default;
// $base-url-images: "assets/images/" !default;

// $spacers
/*$spacer: 1rem !default;
$spacers: () !default;*/
// stylelint-disable-next-line scss/dollar-variable-default
/*$spacers: (0: 0,
        1: ($spacer * .25), //4px
        2: ($spacer * .5), //8px
        3: $spacer, //16px
        4: ($spacer * 1.25), //20px
        5: ($spacer * 1.5), //24px
        6: ($spacer * 1.5), //24px
        7: ($spacer * 2), //32px
        8: ($spacer * 2.25), //36px
        9: ($spacer * 3), //48px
);*/

// @colors
$hippie-blue: #479ea1 !default;
$jellyfish: #58c2c9 !default;
$neon-carrot: #f3a248 !default;
$lavender: #c779d0 !default;
$bashful-pink: #c84e89 !default;
$brink-pink: #f15f79 !default;
$error: #eb5757 !default;
$body-bg: #eceff3 !default;
$lik-bg-blue: #cfe8e9;
$users-border: #f6f7fc;
$transparent: transparent;
$bashful-blue: #006e9c !default;
$brink-blue: #13a9e8 !default;
$lime-blue: #e7f6fd !default;
$new-blue: #2197c9 !default;
$new-blue-hover: #1d8fc0 !default;

// Accent Colors
$pale-teal: #6fcf97 !default;

// Neutral
$light-sky-blue: #c3fdff !default;

// Gray shades
$white: #ffffff !default;
$black: #000000 !default;
$gray-529: #212529 !default;
$gray-333: #333333 !default;
$gray-f4f: #4f4f4f !default;
$gray-282: #828282 !default;
$gray-dbd: #bdbdbd !default;
$gray-0e0: #e0e0e0 !default;
$gray-979: #979797 !default;
$gray-5f0: #e1e5f0 !default;
$gray-disabled: #c8d0e5 !default;
$secondary-gray: #f9f9f9 !default;
$secondary-gray-gradient: linear-gradient(
  180deg,
  #f9f9f9 0%,
  rgba(255, 255, 255, 0) 100%
);
$black-4a5: #4a5165;

// Random colors for topics
$random-purple-heart: #673ab7 !default;
$random-raspberry: #e91e63 !default;
$random-barney: #9c27b0 !default;
$random-azure-6f3: #2196f3 !default;
$random-azure-9f4: #03a9f4 !default;
$random-topaz: #00bcd4 !default;
$random-teal: #009688 !default;
$random-dark-pastel-green: #4caf50 !default;
$random-green-peas: #8bc34a !default;
$random-cerulean-blue: #3f51b5 !default;
$random-orange-peal: #ff9800 !default;
$random-portland-orange: #ff5722 !default;
$random-star-dust: #9e9e9e !default;
$random-blue-grey: #607d8b !default;
$random-orange: #ff9d2e !default;
$random-red: #ff4b4b !default;
// Forms
$input-border-color: $gray-0e0 !default;
$input-placeholder-color: $gray-282 !default;

//$modal-backdrop-opacity: 0.6;

// Define colors' segment
$primary: $bashful-blue !default;
$secondary: $brink-blue !default;
$gradient-primary: linear-gradient(to right, #006e9c, #13a9e8 50%);
$secondary-plain: $transparent;
// Spinners
$spinner-border-width: 3px !default;
$spinner-width: 1.5rem !default;
$spinner-height: 1.5rem !default;

$borderTwo: 2px !default;
$borderThree: 3px !default;
$borderFive: 5px !default;
$borderSix: 6px !default;

$tag-dark-green: #36c55e;
$tag-light-green: #26a74a;
$tag-light-purple: #af4ed1;
$tag-pink: #e2669a;
$tag-dark-purple: #8666e2;
$tag-dark-yellow: #cba624;
$light-purple: #8892ad;
$tag-blue-1: #2db2cf;
$tag-blue-2: #3e9bd0;
$tag-blue-3: #2197c9;
$tag-red-1: #f26666;

$tag-dark-green-bg: rgba(54, 197, 94, 0.15);
$tag-dark-blue-bg: rgba(62, 155, 208, 0.15);
$tag-pink-bg: rgba(226, 102, 154, 0.15);
$tag-dark-purple-bg: rgba(134, 102, 226, 0.15);
$tag-light-blue-bg: rgba(45, 178, 207, 0.15);
$tag-red-bg: rgba(242, 102, 102, 0.16);
$tag-gold-bg: rgba(203, 166, 36, 0.1);

$light-black: #373947;

$normal-gray: #c8d0e5;

$light-gray: #f4f6f8;

// scss-docs-start form-floating-variables
// $form-floating-height:            1.5rem;
// $form-floating-padding-y:         .375rem !default;
// $form-floating-line-height:       1.25 !default;
// $form-floating-padding-x:         $input-padding-x !default;
// $form-floating-input-padding-t:   1.625rem !default;
// $form-floating-input-padding-b:   .625rem !default;
// $form-floating-label-opacity:     .65 !default;
// $form-floating-label-transform:   scale(.85) translateY(-.5rem) translateX(.15rem) !default;
// $form-floating-transition:        opacity .1s ease-in-out, transform .1s ease-in-out !default;
// scss-docs-end form-floating-variables
