.uploads-body {
  max-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uploads-post-inner-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 0;
}
.no-img img {
  height: 100px;
}
.stay-tuned {
  font-weight: 700;
  font-size: 14px;
}
