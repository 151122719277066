// input
// ---------------------------------------------------
.form-control {
  color: $black;
  background: $light-gray;
  border: none;
  border-radius: $borderFive;
  height: 40px;
  // Customize the `:focus` state to imitate native WebKit styles.
  &:focus {
    // color: $input-focus-color;
    // background-color: $input-focus-bg;
    // outline: 0;
    // border-color: $black;
    border-color: none !important;
    box-shadow: none;
    border: none;
    background-color: $light-gray;
  }

  // Placeholder
  //   &::placeholder {
  //     color: $input-placeholder-color;
  //     // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
  //     opacity: 1;
  //   }
}

.form-floating {
  // stylelint-disable no-duplicate-selectors
  > .form-control {
    // padding: $form-floating-padding-y $form-floating-padding-x;

    // &::placeholder {
    //     color: transparent;
    // }

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: 0.65rem;
      // padding-bottom: $form-floating-input-padding-b;
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    &:-webkit-autofill {
      padding-top: 0.65rem;
      // padding-bottom: $form-floating-input-padding-b;
    }
  }

  > .form-control,
  > .form-select {
    height: 40px;

    // line-height: $form-floating-line-height;
  }

  > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; // allow textareas
    padding: 0.375rem 0.75rem;
    pointer-events: none;
    border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
    transform-origin: 0 0;
    background-color: #fff;
    width: auto;
    @include transition($form-floating-transition);
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-select {
    ~ label {
      opacity: 1;
      transform: scale(0.85) translateY(-12px) translateX(0.15rem);
      color: $gray-282;
      background-color: #ffffff;
      padding: 0 4px;
      margin-left: 8px;
      height: auto;
    }
  }
  // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
  > .form-control:-webkit-autofill {
    ~ label {
      opacity: 1;
      transform: scale(0.85) translateY(-12px) translateX(0.15rem);
      color: $gray-282;
      background: #ffffff;
      padding: 0 4px;
      margin-left: 8px;
      height: auto;
    }
  }
  // stylelint-enable no-duplicate-selectors
}

//Radio group
.radio-group {
  .radio-control {
    display: none;
    & + label {
      position: relative;
      &::before {
        position: absolute;
        left: -24px;
        top: -12px;
        content: "";
        width: 24px;
        height: 24px;
        // background: url("../../img/icons/radio.png");
        cursor: pointer;
        border: 2px solid $bashful-blue;
        border-radius: 15px;
      }
    }

    &:checked {
      & + label {
        &::before {
          // background: url("../../img/icons/radio-checked.png");
          cursor: default;
          background-color: $bashful-blue;
          content: "\2714";
          display: flex;
          color: $white;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &:checked {
      &:disabled {
        & + label {
          &::before {
            // background: url("../../img/icons/radio-checked.png");
            cursor: default;
            content: "\2714";
            background-color: $bashful-blue;
            display: flex;
            color: $white;
            align-items: center;
            justify-content: center;
            cursor: not-allowed;
          }
        }
      }
    }

    &:disabled {
      & + label {
        &::before {
          // background: url("../../img/icons/radio-checked.png");
          cursor: default;
          background-color: $gray-0e0;
          display: flex;
          color: $white;
          align-items: center;
          justify-content: center;
          cursor: not-allowed;
          border: none;
        }
      }
    }
  }
}

// .form-check-input {
//   background-color: $white;
//   border: 2px solid $bashful-blue;
//   border-radius: $borderTwo !important;
// }
// .form-check-input:checked {
//   background-color: $gradient-primary;
// }

.topic-details-check {
  .form-check {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    color: $bashful-blue;
    width: 20px;
    height: 20px;
    border: 2px solid $normal-gray;
    border-radius: 3px;
    // transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    min-height: 20px;
  }

  .form-check::before {
    content: "";
    width: 8px;
    height: 8px;
    // clip-path: polygon(8% 53%, 0 65%, 50% 100%, 100% 16%, 89% 11%, 46% 81%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: $random-orange;
    border-radius: 2px;
  }

  .form-check:checked::before {
    transform: scale(1);
  }
  .form-check:checked {
    background-color: $white;
    background-image: none;
  }
  .form-check:disabled {
    // background-color: $bashful-blue;
    // background-image: none;
    opacity: 0.5;
  }
}

.form-switch {
  // margin-bottom: 17px;
  display: flex;
  padding: 0px;
  justify-content: end;
  align-items: center;
  .login-switch {
    margin-left: 12px;
    margin-right: 0px;
    float: none;
    border-radius: 30px !important;
    height: 28px;
    width: 60px;
    margin-top: 0px;
    &:focus {
      box-shadow: none;
      border-color: rgba(0, 0, 0, 0.25);
    }
  }
}

#formBasicSelectType:disabled + label {
  background: transparent;
}
#formBasicTopicName + label {
  background: transparent;
}
#formBasicTopicName:focus + label {
  background: #fff !important;
}
#formBasicTopicName:valid > label {
  background: #fff !important;
}
#formBasicTopicName:not(:placeholder-shown) {
  + label {
    background: #fff !important;
  }
}
#formBasicTopicSubject + label {
  background: transparent;
}
#formBasicTopicSubject:not(:placeholder-shown) {
  + label {
    background: #fff !important;
  }
}
#formCurrentPassword + label {
  background: transparent;
}
#formCurrentPassword:focus + label {
  background: #fff !important;
}
#formCurrentPassword:not(:placeholder-shown) {
  + label {
    background: #fff !important;
  }
}
#formNewPassword + label {
  background: transparent;
}
#formNewPassword:focus + label {
  background: #fff !important;
}
#formNewPassword:not(:placeholder-shown) {
  + label {
    background: #fff !important;
  }
}
#formConfirmNewPassword + label {
  background: transparent;
}
#formConfirmNewPassword:focus + label {
  background: #fff !important;
}
#formConfirmNewPassword:not(:placeholder-shown) {
  + label {
    background: #fff !important;
  }
}
#formBasicSelectPrivacy:disabled + label {
  background: transparent;
}

.phoneLabel:not(:placeholder-shown) {
  background: transparent;
}
.phoneLabel:placeholder-shown {
  background: #fff !important;
}
#formPhoneNumber:focus + label {
  background: #fff !important;
}
#formPhoneNumber > label.form-label {
  background: transparent;
}
#formPhoneNumber + label {
  background: transparent;
}
#formPhoneNumber:focus + label {
  background: #fff !important;
}
#formPhoneNumber:not(:placeholder-shown) {
  + label {
    background: #fff !important;
  }
}
#formLocation + label {
  background: transparent;
}
#formLocation:focus + label {
  background: #fff !important;
}
#formLocation:not(:placeholder-shown) {
  + label {
    background: #fff !important;
  }
}
#formMotto + label {
  background: transparent;
}
#formMotto:focus + label {
  background: #fff !important;
}
#formMotto:not(:placeholder-shown) {
  + label {
    background: #fff !important;
  }
}
#formProfession + label {
  background: transparent;
}
#formProfession:focus + label {
  background: #fff !important;
}
#formProfession:not(:placeholder-shown) {
  + label {
    background: #fff !important;
  }
}
#formFirstName + label {
  background: transparent;
}
#formFirstName:focus + label {
  background: #fff !important;
}
#formFirstName:not(:placeholder-shown) {
  + label {
    background: #fff !important;
  }
}
#formLastName + label {
  background: transparent;
}
#formLastName:focus + label {
  background: #fff !important;
}
#formLastName:not(:placeholder-shown) {
  + label {
    background: #fff !important;
  }
}
#formDOB + label {
  background: transparent;
}
#formDOB:focus + label {
  background: #fff !important;
}
#formDOB:not(:placeholder-shown) {
  + label {
    background: #fff !important;
  }
}
#formBasicEmail + label {
  background: transparent;
}
#formBasicEmail:focus + label {
  background: #fff !important;
}
#formBasicEmail:not(:placeholder-shown) {
  + label {
    background: #fff !important;
  }
}
input#formBasicEmail + label {
  background: transparent !important;
}
input#formBasicEmail:focus + label {
  background: #fff !important;
}
input#formBasicEmail:not(:placeholder-shown) {
  + label {
    background: #fff !important;
  }
}
.verifyBadge {
  position: absolute;
  right: 0px;
  z-index: 99999;
  height: 20px;
  width: 20%;
  top: 10px;
}
.verifyBadgeInput {
  width: 100%;
  padding-right: 100px;
}
.verifyBadgeButton {
  position: absolute;
  right: 3px;
  bottom: 0px;
  font-weight: 500;
  border: 0;
  color: #ff9d2e;
  outline: none;
  margin: 0;
  padding: 0 10px;
  border-radius: 100px;
  z-index: 2;
}
input#formBasicTopicName {
  // padding-top: 19px !important;
  // padding-left: 10px !important;
  height: 40px;
}
// input#formBasicTopicSubject {
//   // padding-top: 12px !important;
//   // padding-left: 10px !important;
// }
/* Brand/Light Gray
Light Border
*/
input.brand-primary {
  background: #ffffff;
  border: 1px solid #e1e5f0;
  border-radius: 5px;
}
input.brand-primary:active {
  background: #ffffff !important;
  border-radius: 5px !important;
  border: 1px solid rgba(33, 151, 201, 1) !important;
}
input.brand-primary:focus {
  background: #ffffff !important;
  border-radius: 5px;
  border: 1px solid rgba(33, 151, 201, 1) !important;
}
input.brand-primary-error {
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid rgba(255, 75, 75, 1);
}
