.profile {
  .profile-row {
    .card {
      border-right: 1px solid $gray-0e0;
    }
    .sidebar {
      .accordion-header {
        background-color: $white;
        h5 {
          color: $gray-f4f;
          font-size: 20px;
        }
      }
    }
  }
  .cover {
    background-image: url("../../img/profile-cover.svg");
    height: 200px;
    @include media-breakpoint-down(lg) {
      background-position-x: center;
      border-radius: 0px;
    }
    background-color: $bashful-blue;
    background-repeat: no-repeat;
  }
  .header {
    padding: 22px 0px;
  }
  .tab-header {
    background: $brink-blue;
    border-radius: $borderTwo;
    padding: 16px;
    color: $white;
  }
  .label {
    color: #4f4f4f;
    font-weight: bold;
    font-size: 12px;
  }
  .details {
    // padding: 20px 45px;
    background-color: $white;
  }
  .purchase-acc {
    .accordion-body {
      padding: 0px;
    }
    .details.purchase-wrp {
      padding: 0px;
      background-color: rgba(19, 169, 232, 0.1);
      .row-flex {
        padding: 20px;
      }
      .purchase-card {
        background-color: $white;
        // max-height: 190px;
        max-width: 206px;
        display: flex;
        flex-direction: column;
        padding: 10px 22px;
        border-radius: 20px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
        .label {
          margin-top: 10px;
          font-size: 18px;
        }
        span {
          color: $brink-blue;
          font-weight: bold;
        }
        span:nth-child(1) {
          font-size: 40px;
        }
        span:nth-child(2) {
          color: rgba(19, 169, 232, 0.5);
        }
      }
      .feat-list {
        background-color: $white;
        padding: 20px;
        li {
          list-style: disc;
          padding: 10px;
          list-style-position: inside;
          border-bottom: 1px solid $gray-0e0;
        }
      }
    }
  }

  .profile-info {
    .profile-main-img {
      position: absolute;
      height: 200px;
      width: 200px;
      top: 90px;
      right: 0;
      left: 40px;
      border: 5px solid white;
      border-radius: $borderTwo;
    }
    .profile-main-img-placeholder {
      background: #12a8e8;
    }
    
    .profile-img-edit {
      height: 22px;
      width: 22px;
    }
    .profile-avatar {
      font-size: 78px;
      color: #fff;
      position: absolute;
      top: 130px;
      left: 70px;
    }
  }
  .profile-accordian {
    .accordion-header {
      background: transparent;
      padding: 0px;
    }
    .accordion-item {
      background: transparent;
      margin-bottom: 30px;
      border: none;
      @include media-breakpoint-down(lg) {
        margin-bottom: 0px;
      }
    }

    .accordion-button {
      color: $white;
      box-shadow: none;
      border-radius: $borderTwo;
      font-weight: 700;
      font-size: 16px;
      padding: 1.15rem 1.25rem;
      background-color: $brink-blue;
    }

    .accordion-button::after {
      color: $white;
      background-image: url("../../img/icons/profile/downArrowActive.svg");
    }
  }
  .profile-privacy-list {
    background: linear-gradient(
      180deg,
      #fafafa 0%,
      rgba(255, 255, 255, 0) 100%
    );
    .activity-tabs {
      width: 50%;
      border-bottom: 0;
      background-color: transparent;
    }
    .profile-circle-header {
      // background-color: red;
      border-top: 1px solid $gray-5f0;
      border-bottom: 1px solid $gray-5f0;
    }
  }
}
.profilePicModal {
  padding-left: 0px;
  padding-right: 0px;
}

input[type="range"] {
  height: 34px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  // box-shadow: 1px 1px 1px #000000;
  @include radial-gradient($bashful-blue, $brink-blue);
  background: radial-gradient($bashful-blue, $brink-blue);
  border-radius: 10px;
  // border: 0px solid #010101;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 5px #616161;
  // border: 1px solid #00001e;
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}
.profileSlider {
  display: flex;
  width: 70%;
  align-self: center;
  margin: 0 auto;
  .sliderInput {
    margin: 0px 20px;
  }
  .sliderDown {
    width: 15px;
  }
  .sliderUp {
    margin-right: 20px;
  }
}

.connAccModHeader {
  .accordion-button {
    margin: 0px 30px;
  }
}

.accordion-button:focus {
  z-index: 0;
}

.form-switch.profile-count-switch {
  // padding-left: 2.5em;
  .form-check-label {
    // margin-left: 10px;
  }
  .form-check-input {
    width: 45px;
    border: 2px solid $random-orange;
    height: 25px;
    margin-left: 0px;
    &::before {
      content: "";
      width: 100px;
      height: 100px;
      clip-path: polygon(8% 53%, 0 65%, 50% 100%, 100% 16%, 89% 11%, 46% 81%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
    }
  }
  .form-check-input:checked {
    background-color: $random-orange;
  }
  .form-check-input:focus {
    box-shadow: none;
  }
}
.profile-sidebar-main {
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  background-color: transparent !important;
  height: auto;
  box-shadow: none !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.profile-sidebar-main .left-panel .header {
  height: 64px;
  position: relative;
  background-color: #006e9c;
}
.profile-sidebar-main .left-panel .profile-info img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 30px;
  display: block;
  margin: 0 auto;
}
.profile-topics {
  background: #fff !important;
}

.profile-count-data {
  div {
    background-color: $light-gray;
    border-radius: $borderFive;
  }
}

.profile-connections-list {
  li {
    list-style: none;
    flex: 0 0 32.333333%;
  }
}
.editProfile {
  .form-control {
    background-color: $white;
    border: 1px solid $normal-gray;
  }
}

.profile-main-img-edit {
  position: absolute;
  top: 60px;
  right: 0;
  left: 170px;
  // border-radius: 50%;
  // background: #ff9d2e;
  height: 35px;
  width: 35px;
  // border: 3px solid $white;
}
