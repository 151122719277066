.notification {
  .connection {
    padding: 20px;
    border-bottom: 1px solid #bdbdbd;
    border-radius: 0px;
    img {
      vertical-align: sub;
    }
    .profile-info {
      display: flex;
      align-items: center;
    }

    figcaption {
      margin-left: 10px;
      display: inline-block;
      font-size: 20px;
      color: #4f4f4f;
      font-weight: 700;
    }
  }
}
.info-banner {
  background: #e5f5fe;
  color: $light-black;
  // text-align: center;
  padding: 10px;
  font-size: 12px;
}
.notification-tooltip {
  --bs-tooltip-bg: #e91e63 !important;
  //background-color: #e91e63 !important;
  z-index: 0;
  // display: none;
}
.live {
  height: 12px;
  width: 12px;
  display: block;
  border-radius: 50%;
  background-color: #ff69b4;
  animation: pulse 1500ms infinite;
}

@keyframes pulse {
  0% {
    box-shadow: red 0 0 0 0;
  }
  75% {
    box-shadow: red 0 0 0 16px;
  }
}
