/*  =btn-primary
-------------------------------------------------------------- */
.btn.btn-primary {
  border-radius: $borderFive;
  color: #fff;
  background: $new-blue;
  border: 0;
  height: 40px;
  min-width: 53px;
  padding: 0px 24px;

  &.btn-primary:hover,
  &.btn-primary:active,
  &.btn-primary:focus {
    color: #fff;
    box-shadow: none;
  }
  &.btn-primary:disabled,
  &.btn-primary.disabled {
    color: #fff;
  }
}

// btn-outline-primary
// ---------------------------------------------------
.btn.btn-outline-primary {
  position: relative;
  height: 40px;
  border-radius: $borderFive;
  background: $white;
  min-width: 53px;
  padding: 0px 24px;
  border: 1px solid $new-blue;
  color: $new-blue;

  &.btn-outline-primary:hover,
  &.btn-outline-primary:active,
  &.btn-outline-primary:focus {
    color: $new-blue;
    background-color: #fff;
  }
}

// btn-link
// ---------------------------------------------------
.btn.btn-link {
  font-weight: 700;
  color: $brink-blue;
  height: 58px;
  text-decoration: none;

  &.btn-link:hover,
  &.btn-link:active,
  &.btn-link:focus {
    color: $brink-blue;
    box-shadow: none;
  }

  &.btn-link:focus {
    background-color: #f3e4e7;
  }
}

// size
// ---------------------------------------------------
.btn.btn-md {
  height: 46px;
}
.btn.btn-sm {
  height: 34px;
}

// btn-plain (used for icons, etc.)
// ---------------------------------------------------
.btn-plain {
  line-height: normal;
  padding: 0;
  border: 0;

  &:focus {
    box-shadow: none;
  }
}

.btn-block {
  width: 100%;
}

// rounded pill
//------------------------------

.rounded-pill {
  width: 101px;
  height: 34px;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-back {
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  // @include radial-gradient($bashful-blue, $brink-blue);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  color: $white;
  img {
    margin-right: 10px;
  }
}

.btn-show-more {
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  border: 0;
  padding: 0;
  margin: 5px auto;
  background-color: transparent;
  color: $white;
  img {
    margin-left: 10px;
  }
}

.btn-reacts {
  padding: 7px 16px 7px 40px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  color: $white;
  border: 0;
  border-radius: 100px;
  margin-right: 10px;
}

.btn-comment {
  background: $random-dark-pastel-green url("../../img/icons/comment-icon.svg")
    no-repeat 16px center;
}

.btn-like {
  background: $hippie-blue url("../../img/icons/like-icon.svg") no-repeat 16px
    center;
}

.btn-more-info {
  width: 24px;
  height: 24px;
  border: 0;
  background: url("../../img/icons/Threedots.svg") no-repeat center;
}

.btn-more-info-white {
  width: 4px;
  height: 24px;
  border: 0;
  background: url("../../img/icons/more-icon-white.svg") no-repeat center;
}

/*  =btn-secondary
-------------------------------------------------------------- */
.btn.btn-secondary {
  border-radius: $borderFive;
  color: #fff;
  background: $brink-blue;
  width: 64px;
  // text-align: center;

  &.btn-secondary:hover,
  &.btn-secondary:active,
  &.btn-secondary:focus {
    color: #fff;
    box-shadow: none;
  }
  &.btn-secondary:disabled,
  &.btn-secondary.disabled {
    color: #fff;
  }
}

/*  =btn-tertiary
-------------------------------------------------------------- */
.btn.btn-tertiary {
  border-radius: $borderFive;
  color: $new-blue;
  background: $white;
  // width: 80%;
  height: 40px;
  border: 1px solid $new-blue;
  padding: 0px 24px;
  &.btn-tertiary:hover,
  &.btn-tertiary:active,
  &.btn-tertiary:disabled,
  &.btn-tertiary.disabled {
    color: $new-blue;
  }
}
// .modal-dialog button.btn-close {
//   color: #fff !important;
// }
// .btn.btn-link.btn-link:focus {
//   background-color: #eaf6fd !important;
//   height: 35px;
// }

.btn.btn-orange {
  border-radius: $borderFive;
  color: $white;
  background: $random-orange;
  // width: 80%;
  height: 40px;
  border: 1px solid $random-orange;
  padding: 0px 24px;
  &.btn-tertiary:hover,
  &.btn-tertiary:active,
  &.btn-tertiary:disabled,
  &.btn-tertiary.disabled {
    color: $white;
  }
}

/* Brand/Primary
Brand Primary, Button, Link
*/
.brand-primary-btn-lg {
  background: $new-blue;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 16px 35px;
  width: 165px;
  height: 50px;
  /* Brand/Primary */
  color: $new-blue;
}
.brand-primary-btn-reg {
  background: $new-blue;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 12px 25px;
  width: 144px;
  height: 40px;
  /* Brand/Primary */
  color: $new-blue;
}
.brand-primary-btn-sm {
  background: $new-blue;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 10.5px 15px;
  width: 98px;
  height: 34px;
  /* Brand/Primary */
  color: $new-blue;
}
.brand-primary-btn-tiny {
  background: $new-blue;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 7.5px 15px;
  width: 98px;
  height: 30px;
  /* Brand/Primary */
  color: $new-blue;
}
.brand-primary-btn-lg:hover {
  background: $new-blue-hover;
}
.brand-primary-btn-reg:hover {
  background: $new-blue-hover;
}
.brand-primary-btn-sm:hover {
  background: $new-blue-hover;
}
.brand-primary-btn-tiny:hover {
  background: $new-blue-hover;
}
.brand-primary-btn-lg:disabled,
.brand-primary-btn-lg[disabled] {
  background: $gray-disabled;
}
.brand-primary-btn-reg:disabled,
.brand-primary-btn-reg[disabled] {
  background: $gray-disabled;
}
.brand-primary-btn-sm:disabled,
.brand-primary-btn-sm[disabled] {
  background: $gray-disabled;
}
.brand-primary-btn-tiny:disabled,
.brand-primary-btn-tiny[disabled] {
  background: $gray-disabled;
}

/* Brand/Primary Ghost Buttons
Brand Primary, Button, Link
*/
.brand-primary-btn-ghost-lg {
  background: $white;
  border: 1px solid $new-blue;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 16px 35px;
  width: 165px;
  height: 50px;
  /* Brand/Primary */
  color: $new-blue;
}
.brand-primary-btn-reg {
  background: $white;
  border: 1px solid $new-blue;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 12px 25px;
  width: 144px;
  height: 40px;
  /* Brand/Primary */
  color: $new-blue;
}
.brand-primary-btn-sm {
  background: $white;
  border: 1px solid $new-blue;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 10.5px 15px;
  width: 98px;
  height: 34px;
  /* Brand/Primary */
  color: $new-blue;
}
.brand-primary-btn-tiny {
  background: $white;
  border: 1px solid $new-blue;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 7.5px 15px;
  width: 98px;
  height: 30px;
  /* Brand/Primary */
  color: $new-blue;
}
.brand-primary-btn-lg:hover {
  background: $new-blue-hover;
}
.brand-primary-btn-reg:hover {
  background: $new-blue-hover;
}
.brand-primary-btn-sm:hover {
  background: $new-blue-hover;
}
.brand-primary-btn-tiny:hover {
  background: $new-blue-hover;
}
.brand-primary-btn-lg:disabled,
.brand-primary-btn-lg[disabled] {
  background: $gray-disabled;
}
.brand-primary-btn-reg:disabled,
.brand-primary-btn-reg[disabled] {
  background: $gray-disabled;
}
.brand-primary-btn-sm:disabled,
.brand-primary-btn-sm[disabled] {
  background: $gray-disabled;
}
.brand-primary-btn-tiny:disabled,
.brand-primary-btn-tiny[disabled] {
  background: $gray-disabled;
}
