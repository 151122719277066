.onboarding-main {
  display: flex;
  height: 100%;
  position: fixed;
  width: 100%;
  @include respond-to("medium") {
    flex-wrap: wrap;
    position: static;
  }
}

.onboarding-content {
  width: 50%;
  background: linear-gradient(180deg, #006e9c 0%, #13a9e8 100%);
  //background: #f6f6f6;
  color: $white;

  .desc {
    color: $white;
    span {
      display: block;
    }
    @include respond-to("medium") {
      display: none;
    }
  }

  h3 {
    @include respond-to("medium") {
      display: none;
    }
  }

  @include respond-to("medium") {
    width: 100%;
    text-align: center;
  }
}

.onboarding-signup {
  background: #f6f6f6 !important;
}

.onboarding-illustration {
  height: 100%;
  //background-image: url("../../img/icons/onboarding/icons.svg");
  //   opacity: 0.1;
  width: 100%;
  //position: absolute;
  z-index: 1;
  padding: 36px 60px;
  @include respond-to("medium") {
    position: relative;
  }
}

.onboarding-icons {
  position: absolute;
  opacity: 0.55;
  z-index: -1;
}

.brand {
  text-decoration: none;
  color: $white;
  margin-bottom: 30px;

  img {
    margin-bottom: 30px;
    @include respond-to("medium") {
      margin-bottom: 15px;
    }
  }

  figure {
    margin: 0 0 20px 0;
    @include respond-to("medium") {
      margin-bottom: 50px;
    }
  }
  figcaption {
    font-size: 48px;
    line-height: 41px;
    font-weight: 700;
  }

  &:hover {
    color: $white;
  }
}

.onboarding-form,
.forgot-form {
  width: 50%;
  min-width: 460px;
  padding: 46px 40px;
  background: #f6f6f6;
  text-align: center;

  .form-group,
  .form-floating {
    margin-bottom: 30px;

    @include respond-to("medium") {
      margin-bottom: 20px;
    }

    label {
      color: $gray-282;
      padding: 0.6rem 0.75rem;
    }

    .otp-control {
      border-width: 0 0 1px 0;
      border-radius: 0;
    }
  }

  .form-control {
    padding: 0.688rem 0.75rem;
  }

  .form-floating {
    & > .form-control,
    & > .form-select {
      height: 48px;
    }
  }

  @include respond-to("medium") {
    width: 100%;
    min-width: auto;
    border-radius: 15px 15px 0 0;
    position: relative;
    top: -40px;
  }

  @include respond-to("small") {
    padding: 25px;
  }
  .highlightText {
    color: $brink-blue;
  }
}

.onboarding-list {
  margin-top: 30px;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    figure {
      margin: 0 16px 0 0;
    }

    .card-heading {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    p {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 0;
    }
  }
  img {
    height: auto;
    width: 100%;
    max-height: 600px !important;
  }

  @include respond-to("medium") {
    display: none;
  }
}

.social-list {
  margin-top: 50px;
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      margin-right: 24px;
      &:first-child {
        @include respond-to("large") {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }

  @include respond-to("medium") {
    display: none;
  }
}

.timer {
  font-size: 14px;
  text-align: right;
  font-weight: 700;

  span {
    color: #828282;
    font-weight: 400;
  }
}

.error {
  color: $error;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  text-align: left;
  margin-top: 4px;
}

.form-control,
.select-control {
  &.error-control {
    border-color: $error;
  }
}

.forgot-main {
  width: 100%;
  height: 100vh;
  background: linear-gradient(90deg, $bashful-blue 0%, $brink-blue 100%);
}

.forgot-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  //background-image: url(../../../assets/img/illustration.svg);
  background: linear-gradient(180deg, #006e9c 0%, #13a9e8 100%);
}

.forgot-form {
  width: 100%;
  max-width: 368px;
  padding: 32px 29px;
  background: $white;
  border-radius: 15px;
  text-align: center;

  .form-group,
  .form-floating {
    margin-bottom: 30px;
  }

  .form-floating {
    & > .form-control,
    & > .form-select {
      height: 48px;
    }
  }

  @include respond-to("small") {
    padding: 25px;
  }
}

.eye-open {
  background: url(../../../assets/img/icons/icon-eye-on.svg) no-repeat
    calc(100%) center;
  width: 30px;
  height: 20px;
  display: inline-block;
  position: absolute;
  top: 14px;
  right: 10px;
  opacity: 0.5;
}

.eye-close {
  background: url(../../../assets/img/icons/icon-eye-off.svg) no-repeat
    calc(100%) center;
  width: 30px;
  height: 20px;
  display: inline-block;
  position: absolute;
  top: 14px;
  right: 10px;
  opacity: 0.5;
}

// .onboarding-illustration h3 {
//   color: #fff;
// }

.onboarding-form .form-floating > label {
  background: transparent;
}
.forgot-form .form-floating > label {
  background: transparent;
}
.onboarding-form {
  .title {
    font-size: 28px;
    font-weight: 700;
  }
  p.alreadyMember {
    font-size: 14px;
    font-weight: 700;
  }
  .desc {
    margin-top: 0px;
    font-weight: normal;
    font-size: 14px;
    color: #828282;
  }
}

.privacy-terms {
  color: $random-orange !important;
}

.forgetPassword-btn {
  background-color: $white !important;
  color: $brink-blue !important;
  border: none;
}

.onboarding-form .form-container {
  width: 90%;
  max-width: 560px;
  background: #fff;
  padding: 50px;
  border-radius: 10px;
}

.onboarding-form .form-container p.title,
p.desc {
  text-align: left;
}
.form-control {
  border: 1px solid #e1e5f0;
  border-radius: 5px;
  background: #ffffff;
}
.desc-bottom {
  position: absolute;
  bottom: 30px;
}
input.vi {
  border: none !important;
}
:where(.vi__character) {
  border: 1px solid #e1e5f0 !important;
  border-radius: 5px;
  background: #fff;
  height: 50px !important;
  width: 50px !important;
}
.vi__character--inactive {
  //background: #fff !important;
}
